import {IAppScreenViewSchema, IPageViewSchema} from "./Trackers/SnowPlow";
/*
  TODO: When moving away from Google Tag Manager move this functionality into Snowplow.ts and replace calls to
        window.snowplow with this.tracker. Update spyOn in tests to reflect the window.snowplow -> this.tracker change.
 */
export default class GDPRCompliantSessionTrackingHelper {
    /**
     * Used for first party GDPR compliant session tracking. Should fire an event to snowplow on a page view/session
     * start if the session is a new session<br>
     *   For the purpose of this type of tracking a session is regarded as a new session
     *   <ul>
     *     <li>if there is no expiry timestamp in the session storage</li>
     *     <li>or if the session has been dormant for a given amount of time.</li>
     *   </ul>
     *   NOTE: When updating this method REMEMBER to update the equivalent part in Google Tag Manager. In that case
     *   replace 'window.snowplow'/'window.jp_snowplow', 'trackStructEvent:snowplow'/'trackStructEvent:page'.
     *   If you are reading this from Google Tag Manager, update the source code in the frontend project, transpile it and
     *   copy it GTM/here.
     * @param schema
     */
    public static firstPartySessionTracking(schema: IPageViewSchema | IAppScreenViewSchema): void {
        const sessionStorageExpiryKey: string = "first_party_session_expiry_timestamp";
        /**
         * The maximum amount of time (in milliseconds) a session can be dormant before being regarded as a new session.
         * That is, if !now_timestamp or now_timestamp <= past_timestamp + sessionExpiryLimit then session is considered
         * a new session.
         * @private
         */
        const sessionExpiryLimit: number = 30 * 60 * 1000; // min * sec * millis
        const sessionStorageExpiryValue: string | null = sessionStorage.getItem(sessionStorageExpiryKey);
        const now: number = (new Date).getTime();
        const isNewSession: boolean = !sessionStorageExpiryValue || sessionStorageExpiryValue <= `${now}`;

        if (isNewSession) {
            window.snowplow(
                'trackStructEvent:snowplow',
                'user_activity',
                'session_start',
                '', '', '',
                [schema]
            );
        }
        sessionStorage.setItem(sessionStorageExpiryKey, `${now + sessionExpiryLimit}`);
    }
}
