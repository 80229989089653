import {IPageViewTracker} from "./IPageViewTracker";
import {TrackingInfo} from "../TrackingInfo";
import {IClickTracker} from "./IClickTracker";
import TrackingHelper from "../TrackingHelper";
import {IInViewTracker} from "./IInViewTracker";
import GDPRCompliantSessionTrackingHelper from "../GDPRCompliantSessionTrackingHelper";
import {IScrollReachTracker} from './IScrollReachTracker';
import {IVideoTracker} from "./IVideoTracker";
import {IArticleTypeTracker} from "./IArticleTypeTracker";
import {IAppScreenViewTracker} from "./IAppScreenViewTracker";

interface ISchema {
    schema: string,
    data: object
}

// See https://github.com/Jyllands-Posten/SnowplowDeployments/blob/master/schemas/schemas/dk.jyllands-posten/heartbeat/jsonschema/1-0-1
interface IHeartBeatSchema extends ISchema {
    schema: string,
    data: {
        enabled_for_views_ratio: number;
        seconds_between_beats: number;
        count: number;
    }
}

interface IUserSchema extends ISchema {
    schema: string,
    data: {
        anon_id: string,
        user_id: string,
        user_authenticated: string,
        user_authorized: string,
        ab_group: number,
        corp_id: string,
        grp_authenticated: string,
        grp_authorized: string
    }
}

export interface IPageViewSchema extends ISchema {
    schema: string,
    data: {
        client: string,
        section_id: number,
        section_path_id: number[],
        section_name: string,
        content_id: number,
        content_type: string,
        page_name: string,
        page_type: string,
        content_context: string,
        page_restricted: string,
        page_restricted_type: string,
        site: string,
        sub_site: string,
        editorial_category: string
    }
}

/**
 * @see https://github.com/Jyllands-Posten/snowplow-iglu-server/blob/main/schemas/schemas/dk.jyllands-posten/native_app_screen_view/jsonschema/2-0-1
 */
export interface IAppScreenViewSchema extends ISchema {
    schema: string,
    data: {
        section_id: number,
        content_id: number,
        page_restricted: string,
        page_restricted_type: string,
        site: string,
        external_referer?: string,
    }
}

/**
 * @see https://github.com/snowplow/iglu-central/blob/master/schemas/com.snowplowanalytics.mobile/application/jsonschema/1-0-0
 */

export interface IMobileApplicationSchema extends ISchema {
    schema: string,
    data: {
        version: string,
        build: string,
    }
}

interface IClickSchema extends ISchema {
    schema: string,
    data: {
        id: string,
        classes: string[],
        tracking_labels: string[],
        url: string,
        additional_data: IAdditionalData[]
    }
}

interface IElementInViewSchema extends ISchema {
    schema: string,
    data: {
        id: string,
        tag_name: string,
        classes: string[],
        tracking_labels: string[],
        timestamp: string, //Formats defined in https://tools.ietf.org/html/rfc3339. (date-time)
        additional_data: IAdditionalData[]
    }
}

interface IAccessAgreementSchema extends ISchema {
    schema: string,
    data: {
        access_agreements: IAccessAgreement[]
    }
}

interface IArticleSchema extends ISchema {
    schema: string,
    data: {
        article_ids: number[]
    }
}

interface IExperimentSchema extends ISchema {
    readonly schema: string
    readonly data: SplitTestContext
}

export interface IAdditionalData {
    readonly key: string
    readonly value: string
}

export interface IAccessAgreement {
    readonly account_number: string
    readonly id: string
}

export class SnowPlow implements IAppScreenViewTracker, IPageViewTracker, IClickTracker, IInViewTracker, IScrollReachTracker, IVideoTracker, IArticleTypeTracker {

    tracker: Function;
    trackerName: string;
    hasConsent: boolean;
    dynamicContexts: () => any[];
    heartbeatCounter: number;

    static getSnowplowAppId(): string | null {
        return (!window.snowplowConfiguration || !window.snowplowConfiguration.appId) ? null : window.snowplowConfiguration.appId;
    }

    static getSnowplowUrl(): string | null {
        return (!window.snowplowConfiguration || !window.snowplowConfiguration.url) ? null : window.snowplowConfiguration.url;
    }

    static getSnowplowScriptUrl(): string | null {
        return (!window.snowplowConfiguration || !window.snowplowConfiguration.scriptUrl) ? null : window.snowplowConfiguration.scriptUrl;
    }

    static getSnowplowHeartbeatRatio(): number {
        const ratio = (
            window.snowplowConfiguration?.heartbeat?.ratio
        );
        return ratio || 0.2;
    }

    static getSnowplowHeartbeatInterval(): number {
        const interval = (
            window.snowplowConfiguration?.heartbeat?.interval
        );
        return interval || 30;
    }

    constructor(trackerName: string, hasConsent: boolean, referrer: string | null, sendHeartbeats: boolean | null) {
        this.init();
        this.hasConsent = hasConsent;
        this.heartbeatCounter = -1;
        this.dynamicContexts = sendHeartbeats ? (() => {
            this.heartbeatCounter += 1;
            return [SnowPlow.getHeartbeatSchema(this.heartbeatCounter)];
        }) : () => [];

        const appTrackingData = TrackingInfo.getAppTrackingData();
        const url = SnowPlow.getSnowplowUrl();
        const appId = appTrackingData?.app_id || SnowPlow.getSnowplowAppId();
        const platform = appTrackingData ? 'app' : 'web';
        trackerName = appTrackingData?.namespace || trackerName;

        const cookieDomain = window.commonJpData?.site === "finans.dk" ? ".finans.dk" : ".jyllands-posten.dk"

        if (appId && url) {
            this.trackerName = trackerName;
            this.tracker = window.snowplow;

            const anonymousTracking = !hasConsent;

            this.tracker('newTracker', trackerName, url, {
                appId: appId,
                platform: platform,
                anonymousTracking: anonymousTracking,
                cookieDomain: cookieDomain,
                cookieName: '_sp_jp_1_',
                encodeBase64: false, // Default is true
                respectDoNotTrack: false,
                forceSecureTracker: true,
                stateStorageStrategy: 'cookie',
                eventMethod: 'beacon',
                contexts: {
                    webPage: true,
                    gaCookies: false,
                    performanceTiming: false
                },
            });

            if (referrer) window.snowplow('setReferrerUrl', referrer);

            if (sendHeartbeats) {
                const interval = SnowPlow.getSnowplowHeartbeatInterval();
                const delay = interval;
                window.snowplow('enableActivityTracking', delay, interval);
            }
        } else {
            throw new Error("Unable to extract appId or url for snowplow tracker")
        }
    }

    private init() {

        const scriptUrl = SnowPlow.getSnowplowScriptUrl();
        if (!scriptUrl) {
            throw Error("Snowplow script url is unavailable")
        }

        let n: any = {};
        let g: any = {};

        (function (p: any, l: any, o: string, w: string, i: string, n, g) {
            if (!p[i]) {
                p.GlobalSnowplowNamespace = p.GlobalSnowplowNamespace || [];
                p.GlobalSnowplowNamespace.push(i);
                p[i] = function () {
                    (p[i].q = p[i].q || []).push(arguments)
                };
                p[i].q = p[i].q || [];
                n = l.createElement(o);
                g = l.getElementsByTagName(o)[0];
                n.async = 1;
                n.src = w;
                g.parentNode.insertBefore(n, g)
            }
        }(window, document, 'script', scriptUrl, 'snowplow', n, g));

    }

    trackScreenView(trackingInfo: TrackingInfo): void {
        const event = {
            schema: 'iglu:com.snowplowanalytics.mobile/screen_view/jsonschema/1-0-0',
            data: {
                name: trackingInfo.pageName,
                //crypto.randomUUID is not supported in http, so we hardcode an id for dev mode
                id: typeof crypto.randomUUID === "function" ? crypto.randomUUID() : "b29418da-4227-4ea0-8ce9-0ae0c9023274",
            }
        };
        const schemas = SnowPlow.getCommonSchemas(trackingInfo, this.hasConsent);
        this.tracker('trackUnstructEvent', event, schemas);

        const screenViewSchema = SnowPlow.getAppScreenViewSchema(trackingInfo)
        GDPRCompliantSessionTrackingHelper.firstPartySessionTracking(screenViewSchema)
    }

    trackPageView(trackingInfo: TrackingInfo): void {
        const event = 'trackPageView:' + this.trackerName;

        const schemas = SnowPlow.getCommonSchemas(trackingInfo, this.hasConsent);
        this.tracker(event, null, schemas, this.dynamicContexts);

        const pageViewSchema = SnowPlow.getPageViewSchema(trackingInfo)
        GDPRCompliantSessionTrackingHelper.firstPartySessionTracking(pageViewSchema)
    }

    trackClick(trackingInfo: TrackingInfo, clickedElement: HTMLElement, trackedItems: Map<string, string[]>): void {
        const url = TrackingHelper.getHref(clickedElement);

        const trackLabel = TrackingHelper.getTrackedItemsAsString('label', trackedItems);
        const label = (trackLabel) ? trackLabel : '';

        const value = TrackingHelper.getFirstTrackedItem('value', trackedItems);
        const property = TrackingHelper.getFirstTrackedItem('property', trackedItems);


        if (url === null) {
            return;
        }

        // Set additional data
        let additionalData: IAdditionalData[] = TrackingHelper.getAdditionalTrackingData(trackedItems);
        let trackingLabels = TrackingHelper.getTrackingLabels(trackedItems);
        const schemas = SnowPlow.getCommonSchemas(trackingInfo, this.hasConsent);
        schemas.push(SnowPlow.getClickSchema(clickedElement, url, additionalData, trackingLabels));
        const event = 'trackStructEvent:' + this.trackerName;
        this.tracker(event, 'user_activity', 'link_click', label, property, value, schemas);
    }

    trackInView(
        trackingInfo: TrackingInfo,
        inViewElement: HTMLElement,
        inOutView: "elements_in_view" | "elements_out_of_view",
        trackedItems: Map<string, string[]>,
        articleIds: number[],
    ): void {
        const additionalData = TrackingHelper.getAdditionalTrackingData(trackedItems);

        const trackLabel = TrackingHelper.getTrackedItemsAsString('label', trackedItems);
        const label = (trackLabel) ? trackLabel : '';

        const schemas = SnowPlow.getCommonSchemas(trackingInfo, this.hasConsent);

        schemas.push(SnowPlow.getElementInViewSchema(inViewElement, additionalData));

        if (articleIds.length) {
            schemas.push(SnowPlow.getArticleSchema(articleIds));
        }
        const event = 'trackStructEvent:' + this.trackerName;

        this.tracker(event, "user_activity", inOutView, label, "", "", schemas);
    }

    trackScrollReach(trackingInfo: TrackingInfo, scrollReachValue: string, pageViewTimestamp: number, eventName: string) {
        const event = 'trackStructEvent:' + this.trackerName;

        const schemas = SnowPlow.getCommonSchemas(trackingInfo, this.hasConsent);
        schemas.push(SnowPlow.getPageViewTimingSchema(pageViewTimestamp));
        this.tracker(event, "user_activity", eventName, "", "", scrollReachValue, schemas);
    }

    trackVideo(trackingInfo: TrackingInfo, videoElement: HTMLElement, videoAction: String, trackedItems: Map<string, string[]>, articleIds: number[]): void {
        const additionalData = TrackingHelper.getAdditionalTrackingData(trackedItems);
        const event = 'trackStructEvent:' + this.trackerName;
        const schemas = SnowPlow.getCommonSchemas(trackingInfo, this.hasConsent);

        schemas.push(SnowPlow.getElementInViewSchema(videoElement, additionalData));

        if (articleIds.length) {
            schemas.push(SnowPlow.getArticleSchema(articleIds));
        }

        this.tracker(event, "user_activity", "video_tracking", videoAction, "", "", schemas);
    }

    trackArticleType(trackingInfo: TrackingInfo, articleType: string): void {
        const event = 'trackStructEvent:' + this.trackerName;
        const schemas = SnowPlow.getCommonSchemas(trackingInfo, this.hasConsent);
        this.tracker(event, "article", "view", articleType, "", "", schemas);
    }

    public static getCommonSchemas(trackingInfo: TrackingInfo, hasConsent: boolean): ISchema[] {
        let schemas: ISchema[];
        if (trackingInfo.appTracking) {
            schemas = [
                SnowPlow.getAppScreenViewSchema(trackingInfo),
                SnowPlow.getUserSchema(trackingInfo, hasConsent),
                SnowPlow.getMobileApplicationSchema(trackingInfo)];
        } else {
            schemas = [
                SnowPlow.getUserSchema(trackingInfo, hasConsent),
                SnowPlow.getPageViewSchema(trackingInfo),
            ];
        }

        if (trackingInfo.accessAgreements.length > 0) {
            schemas.push(SnowPlow.getAccessAgreementSchema(trackingInfo));
        }

        const splitTestExperiments: Array<SplitTestExperiment> = [
            ...window.trackedSplitTestExperiments ?? [],
            window.trackedFrontendPlatformExperiment,
            window.trackedRecommendationExperiment,
        ].filter(experiment => experiment !== undefined)

        if (splitTestExperiments.length > 0) {
            schemas.push(SnowPlow.getExperimentSchema(splitTestExperiments))
        }

        return schemas;
    }

    public static getUserSchema(trackingInfo: TrackingInfo, hasConsent: boolean): IUserSchema {
        const anonId = trackingInfo.anonId === TrackingInfo.NOTSET ? '00000000-0000-0000-0000-000000000000' : trackingInfo.anonId;
        const anonIdWithConsent = hasConsent ? anonId : "99999999-9999-9999-9999-999999999999";

        return {
            schema: "iglu:dk.jyllands-posten/user/jsonschema/2-0-3",
            data: {
                anon_id: anonIdWithConsent,
                user_id: trackingInfo.userId,
                user_authenticated: trackingInfo.userAuthenticated,
                user_authorized: trackingInfo.userAuthorized,
                ab_group: trackingInfo.abGroup,
                corp_id: trackingInfo.grpId,
                grp_authenticated: trackingInfo.grpAuthenticated,
                grp_authorized: trackingInfo.grpAuthorized
            }
        }
    }

    public static getPageViewSchema(trackingInfo: TrackingInfo): IPageViewSchema {
        return {
            schema: 'iglu:dk.jyllands-posten/page_view/jsonschema/3-0-0',
            data: {
                client: trackingInfo.client,
                section_id: trackingInfo.sectionId,
                section_path_id: trackingInfo.sectionPathId,
                section_name: trackingInfo.sectionName,
                content_id: trackingInfo.contentId,
                content_type: trackingInfo.contentType,
                page_name: trackingInfo.pageName,
                page_type: trackingInfo.pageType,
                content_context: trackingInfo.contentContext,
                page_restricted: trackingInfo.pageRestricted,
                page_restricted_type: trackingInfo.pageRestrictedType,
                site: trackingInfo.site,
                sub_site: trackingInfo.subSite,
                editorial_category: trackingInfo.editorialCategory
            }
        }
    }

    public static getAppScreenViewSchema(trackingInfo: TrackingInfo): IAppScreenViewSchema {
        return {
            schema: 'iglu:dk.jyllands-posten/native_app_screen_view/jsonschema/2-0-1',
            data: {
                section_id: trackingInfo.sectionId,
                content_id: trackingInfo.contentId,
                page_restricted: trackingInfo.pageRestricted,
                page_restricted_type: trackingInfo.pageRestrictedType,
                site: trackingInfo.site,
                external_referer: trackingInfo.appTracking?.external_referer,
            }
        }
    }

    public static getMobileApplicationSchema(trackingInfo: TrackingInfo): IMobileApplicationSchema {
        return {
            schema: 'iglu:com.snowplowanalytics.mobile/application/jsonschema/1-0-0',
            data: {
                version: trackingInfo.appTracking?.app_version,
                build: trackingInfo.appTracking?.app_build
            }
        }
    }

    public static getClickSchema(clickedElement: HTMLElement, url: string, additionalData: IAdditionalData[], trackingLabels: string[]): IClickSchema {
        return {
            schema: "iglu:dk.jyllands-posten/link_click/jsonschema/1-0-0",
            data: {
                id: clickedElement.id,
                classes: [""],
                tracking_labels: trackingLabels,
                url: url,
                additional_data: additionalData
            }
        }
    }

    public static getElementInViewSchema(inViewElement: Element, additionalData: IAdditionalData[], timestamp: string = new Date().toISOString()): IElementInViewSchema {
        return {
            schema: "iglu:dk.jyllands-posten/element_in_view/jsonschema/1-0-0",
            data: {
                id: inViewElement.id,
                tag_name: "",
                classes: [""],
                tracking_labels: [""],
                timestamp: timestamp,
                additional_data: additionalData
            }
        }
    }

    public static getAccessAgreementSchema(trackingInfo: TrackingInfo): IAccessAgreementSchema {
        const accessAgreements: IAccessAgreement[] = trackingInfo.accessAgreements.map(a => ({
            account_number: a.accountNumber,
            id: a.accessAgreementId.toString()
        }))
        return {
            schema: "iglu:dk.jyllands-posten/access_agreements/jsonschema/1-0-0",
            data: {
                access_agreements: accessAgreements
            }
        }
    }

    public static getArticleSchema(articleIds: number[]): IArticleSchema {
        return {
            schema: "iglu:dk.jyllands-posten/articles/jsonschema/1-0-0",
            data: {
                article_ids: articleIds
            }
        }
    }

    public static getHeartbeatSchema(n: number): IHeartBeatSchema {
        const ratio = SnowPlow.getSnowplowHeartbeatRatio();
        const interval = SnowPlow.getSnowplowHeartbeatInterval();
        const result = {
            schema: "iglu:dk.jyllands-posten/heartbeat/jsonschema/1-0-0",
            data: {
                enabled_for_views_ratio: ratio,
                seconds_between_beats: interval,
                count: n
            }
        };
        return result;
    }


    public static getPageViewTimingSchema(pageViewTimestamp: number) {
        let sincePageViewMs: number;
        if (!isNaN(pageViewTimestamp)) {
            sincePageViewMs = new Date().getTime() - pageViewTimestamp;
        } else {
            sincePageViewMs = -1;
        }

        return {
            schema: "iglu:com.snowplowanalytics.snowplow/timing/jsonschema/1-0-0",
            data: {
                "category": "page_view",
                "variable": "ms_since_page_view",
                "timing": sincePageViewMs
            }
        }
    }

    public static getExperimentSchema(experiments: ReadonlyArray<SplitTestExperiment>): IExperimentSchema {
        return {
            schema: "iglu:dk.jyllands-posten/splittest_experiment/jsonschema/2-0-0",
            data: {experiments},
        }
    }
}

type SplitTestContext = {
    readonly experiments: ReadonlyArray<SplitTestExperiment>
}

// coordinate this with `app/helpers/SplitTests.scala`
// data on this form is sent to SnowPlow; changes *MUST* be coordinated
export type SplitTestExperiment = {
    readonly scope: string // experimentId
    readonly variant: string
    readonly owner: string
    readonly view: string
    readonly element: string
    readonly misc: string // free text
}
