import {Tracking} from "./Tracking";
import {SnowPlow} from "./Trackers/SnowPlow";

// Singleton
export class TrackingInit {

    private constructor() {
        const jpTracking = new Tracking;
        window.jpTracking = jpTracking;

        this.initScreenViewTracking(jpTracking);

        this.initPageViewTracking(jpTracking);

        this.initClickTracking(jpTracking);

        this.initInViewTracking(jpTracking);

        this.initScrollReachTracking(jpTracking);

        this.initInlineVideoTracking(jpTracking);

        this.initTopVideoTracking(jpTracking);

        this.initArticleTypeTracking(jpTracking);
    }

    private static _instance: TrackingInit;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    //Trigger a page view (this can be moved to anywhere, but can only be fired once for each tracker.)
    private initPageViewTracking(jpTracking: Tracking) {
        jpTracking.pageView();
    }

    //Trigger a screen view (this can be moved to anywhere, but can only be fired once for each tracker.)
    private initScreenViewTracking(jpTracking: Tracking) {
        jpTracking.screenView();
    }

    private initArticleTypeTracking(jpTracking: Tracking) {
        jpTracking.articleType();
    }

    //Inits click tracking after the DOM content is loaded.
    private initClickTracking(jpTracking: Tracking) {
        document.addEventListener("DOMContentLoaded", () => {
            jpTracking.click(document.body);
        });
    }

    //Inits tracking of all section elements with data-track-inview-zone when they go in-view after the DOM content is loaded.
    private initInViewTracking(jpTracking: Tracking) {
        document.addEventListener("DOMContentLoaded", () => {
            const observeElements = document.querySelectorAll('[data-track-inview-zone], footer[data-track-label]');
            const options: IntersectionObserverInit = {
                root: null,
                threshold: [0, 1],
            }

            observeElements.forEach((element) => {
                jpTracking.inview(element as HTMLElement, options);
            })
        });
    }

    // Inits scrollReach tracking after scroll event is emitted
    private initScrollReachTracking(jpTracking: Tracking) {
        window.addEventListener("scroll", function () {
            const observeElements = document.querySelectorAll('[data-track-scroll-reach]');
            observeElements.forEach((element) => {
                jpTracking.scrollReachInit(element as HTMLElement);
            })
        }, {once: true})

    }

    private initInlineVideoTracking(jpTracking: Tracking) {
        document.addEventListener("DOMContentLoaded", () => {
            const inlineVideoElements = document.querySelectorAll('[video-inline-id]')

            inlineVideoElements.forEach((element) => {
                const videoInlineId = element.getAttribute('video-inline-id')
                const player: any = document.getElementById('inline-player-' + videoInlineId)
                jpTracking.video(element as HTMLElement, player)
            })
        });
    }

    private initTopVideoTracking(jpTracking: Tracking) {
        document.addEventListener("DOMContentLoaded", () => {
            const topVideoElements = document.querySelectorAll('[video-top-id]')

            topVideoElements.forEach((element) => {
                const player: any = document.getElementById('top-player')
                jpTracking.video(element as HTMLElement, player)
            })
        });
    }
}


//We split up inits, so we can manage consent for each tracker and load the jpTracking config before
export function initSnowPlow(hasConsent: boolean, referrer: string | null, sendHeartbeats: boolean | null) {
    const snowPlowTracker = new SnowPlow("snowplow", hasConsent, referrer, sendHeartbeats);

    if (window.jpTracking.trackingInfo.appTracking) {
        window.jpTracking.subscribeScreenView(snowPlowTracker)
    } else {
        window.jpTracking.subscribePageView(snowPlowTracker);
        window.addEventListener('pageshow', (event) => {
            // Send another pageview if the page is restored from bfcache.
            if (event.persisted) {
                window.jpTracking.subscribePageView(snowPlowTracker);
            }
        });
    }

    window.jpTracking.subscribeClick(snowPlowTracker);

    window.jpTracking.subscribeInView(snowPlowTracker);

    window.jpTracking.subscribeScrollReach(snowPlowTracker);

    window.jpTracking.subscribeVideo(snowPlowTracker);

    window.jpTracking.subscribeArticleType(snowPlowTracker);
}

window.initSnowPlow = initSnowPlow;

TrackingInit.Instance;
